import React, { useState, useRef,useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { CMSRequest } from "../Services/serviceApi";
import { useNavigate } from "react-router-dom";
import { generalData } from "../Services/serviceApi";
import { useCookies } from "react-cookie";
import SignIn from "./SignIn";

const Footer = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [cookies] = useCookies(['SESSIONINFO'], ['LOGGEDINFO']);
  const [generalDatas,setGeneralData] = useState([]);
  const [lgShow, setLgShow] = useState(false);
  const [signInDetails,setSignInDetails] = useState('')
  const [url,setURl] = useState('');

  // Read More
  const [isOpen, setIsOpen] = useState(false);
  const paragraphStyle={
    WebkitLineClamp: 2,WebkitBoxOrient: 'vertical',overflow: 'hidden',display: '-Webkit-box',
  }
  // Read More end

  const fetchData = () =>{
    const data = localStorage.getItem('GeneralData');
    if (data != null) {
      const data1 = JSON.parse(data);
      setGeneralData(data1)
    }
  }

  useEffect(()=>{
    fetchData();
    const signin = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ?'Sign out':'Sign In';
    setSignInDetails(signin);
  },[])

  const PageTypeGet = (data) =>{
    CMSRequest(data).then(response => {
      localStorage.removeItem('cmsData');
      localStorage.setItem('cmsData',JSON.stringify(response.data));
      if(response.data !=null){
        if(data === 'about_us'){
          navigate('/about-us')
        }
        if(data === 'why_us'){
          navigate('/why-us')
        }
        if(data === 'term_condition'){
          navigate('/terms-conditions')
        }
        if(data === 'privacy_policy'){
          navigate('/privacy-policy')
        }
        if(data === 'return_policy'){
          navigate('/return-policy')
        }
        if(data === 'shipping_policy'){
          navigate('/shipping-policy')
        }
      }
    });
  }

  /* to={userAuthenticated ? "/my-profile" : "/"} */

  const isAuthenticated = (data) => {
    if(signInDetails =='Sign In'){
      setLgShow(true);
      setURl(data)
    }
    else{
      navigate(data)
    }
  };
  
  return (
    <><footer className="footer-section">
      <Container fluid="md">
        <Row>
          <Col lg={3} md={4} xs={12}>
            <div className="footer-widget logoftr">
              <div className="footer-logo">
                <img src="../assets/images/logo.svg" alt="" />
              </div>
              <div className="footer-logo-name">
                <h5><span>Chotékisan. Badaa dil</span></h5>
                <h5><span>சிறுவிவசாயி. பெரிய உள்ளம்</span></h5>
              </div>
              {/* <Link className="readmore" onClick={() => setIsOpen(!isOpen)} >{isOpen ? 'Read Less' : 'Read More'}</Link> */}
            </div>
          </Col>
          <Col lg={3} md={4} xs={6}>
            <div className="footer-widget">
              <h3>Important Links</h3>
              <Row>
                <Col lg={6} md={6} xs={12}>
                  <ul>
                    <li>
                      <button onClick = {()=>{PageTypeGet('about_us')}} >About Us</button>
                      {/* <Link to={/AboutUs} onClick = {()=>{PageTypeGet('about_us')}} className="">
                        About Us
                      </Link> */}
                    </li>
                    <li>
                      <button onClick = {()=>{PageTypeGet('why_us')}} >Why Us</button>
                      {/* <Link to="/why-us" onClick = {()=>{PageTypeGet('why_us')}} className="">
                        Why Us
                      </Link> */}
                    </li>
                    <li>
                      <Link to="/testimonials" className="">
                        Testimonials
                      </Link>
                    </li>
                    <li>
                      <Link to="/faq" className="">
                        FAQ
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us" className="">
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <ul>
                    <li>
                      <button onClick={() => isAuthenticated('/my-profile')}>
                        My Account
                      </button>
                    </li>
                    <li>
                    <button onClick={() => isAuthenticated("/my-orders")}>
                        My Orders
                      </button>
                    </li>
                    <li>
                    <button onClick={() => isAuthenticated("/track-orders")}>
                        Track Order
                      </button>
                    </li>
                    <li>
                    <button onClick={() => isAuthenticated("/shipping-address")}>
                        My Addresses
                      </button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={3} md={4} xs={6}>
            <div className="footer-widget">
              <h3>Terms & Policy</h3>
              <ul>
                <li>
                  <button onClick = {()=>{PageTypeGet('term_condition')}}>Terms and Conditions</button>
                  {/* <Link to="/terms-conditions" onClick = {()=>{PageTypeGet('term_condition')}}  className="">
                    Terms and Conditions
                  </Link> */}
                </li>
                <li>
                  <button onClick = {()=>{PageTypeGet('privacy_policy')}}>Privacy Policy</button>
                 {/*  <Link to="/privacy-policy" onClick = {()=>{PageTypeGet('privacy_policy')}} className="">
                    Privacy Policy
                  </Link> */}
                </li>
                <li>
                  <button onClick = {()=>{PageTypeGet('return_policy')}}>Return Policy</button>
                  {/* <Link to="/return-policy" onClick = {()=>{PageTypeGet('return_policy')}} className="">
                    Return Policy
                  </Link> */}
                </li>
                {/* <li>
                  <button onClick = {()=>{PageTypeGet('shipping_policy')}}>Shipping Policy</button>
                  {}
                </li> */}
              </ul>
            </div>
          </Col>
          <Col lg={3} md={4} xs={12}>
            <div className="footer-widget">
              <h3>Support</h3>
              <p>
              {/* <span className="text-green">{generalDatas.contact_number}</span> */}
                <Link to={`tel:${generalDatas.support_contact_no}`} >
                  <span className="text-green">{generalDatas.support_contact_no}</span>
                </Link>
              </p>
              <p>
                {/* <Link
                  to={`mailto:${generalDatas.contact_email}`}
                  className="text-mail"
                >
                </Link> */}
                <b>{generalDatas.contact_email}</b>
              </p>
            </div>
            <div className="social-icon">
              <h3>Follow Us</h3>
              <ul>
                <li>
                  {generalDatas.facebook_link && generalDatas.facebook_link !== "javascript:;" ? (
                    <Link to={generalDatas.facebook_link} target="_blank">
                      <span className="iconify">
                        <Icon icon="raphael:facebook" />
                      </span>
                    </Link>
                  ) : (
                    <span className="iconify">
                      <Icon icon="raphael:facebook" />
                    </span>
                  )}
                </li>
                <li>
                  {generalDatas.twitter_link && generalDatas.twitter_link !== "javascript:;" ? (
                    <Link to={generalDatas.twitter_link} target="_blank">
                      <span className="iconify">
                        <Icon icon="fa6-brands:square-x-twitter" />
                      </span>
                    </Link>
                  ) : (
                    <span className="iconify">
                      <Icon icon="fa6-brands:square-x-twitter" />
                    </span>
                  )}
                </li>
                <li>
                  {generalDatas.insta_link && generalDatas.insta_link !== "javascript:;" ? (
                    <Link to={generalDatas.insta_link} target="_blank">
                      <span className="iconify">
                        <Icon icon="uil:instagram-alt" />
                      </span>
                    </Link>
                  ) : (
                    <span className="iconify">
                      <Icon icon="uil:instagram-alt" />
                    </span>
                  )}
                </li>
                <li>
                  {generalDatas.youtube_link && generalDatas.youtube_link !== "javascript:;" ? (
                    <Link to={generalDatas.youtube_link} target="_blank">
                      <span className="iconify">
                        <Icon icon="teenyicons:youtube-solid" />
                      </span>
                    </Link>
                  ) : (
                    <span className="iconify">
                      <Icon icon="teenyicons:youtube-solid" />
                    </span>
                  )}
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="border-line"></div>
          <Col className="copyright">
            <p>© {currentYear} Chotekisan belongs to Silverhill Agrotech Pvt Ltd. All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
      <SignIn lgShow={lgShow} setLgShow={setLgShow} signInDetails={signInDetails} setSignInDetails={setSignInDetails} url ={url} />
    </footer>
    </>
    
  );
};

export default Footer;
