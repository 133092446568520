import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Icon } from "@iconify/react";
import { Link, redirect } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { getCartCount, getSearchproduct, CMSRequest, getCategoryList, generalData, userValidate } from "../Services/serviceApi";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { v4 as uuid } from 'uuid';
import SignIn from "./SignIn";
import ModalWindow from "./ModalWindow";
//import { useHistory } from "react-router-dom";
import YourLocation from "./your-location";
import SkeletonLoader from "./skeleton-loader";
import { useParams } from "react-router-dom";

const Header = (params) => {
  //console.log('params',params);
  //const history = useHistory();
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate()
  const [lgShow, setLgShow] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const [searchKey, setSearchKey] = useState('');
  const [searchResults, setSearchResults] = useState([] || undefined);
  const [cookies, setCookie, removeCookie] = useCookies(['SESSIONINFO'], ['LOGGEDINFO']);
  const [signInDetails, setSignInDetails] = useState('');
  const [url, setURL] = useState('');
  const [GeneralData, setGeneralData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [modalData, setModal] = useState({ show: false, type: '', title: '', message: '' })
  const [isLoading, setIsLoading] = useState(null)
  const userId = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? cookies.LOGGEDINFO : cookies.SESSIONINFO;
  const usertype = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? '' : 'guest';
  const urlData = window.location.href.split('/');
  const [open, setOpen] = useState(false);
  const [pinCode, setPincode] = useState('');
  const [userData, setUserData] = useState('');
  const newRef = useRef(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showin, setShowin] = useState(false);
  const [showout, setShowout] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [count, setCount] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const signin = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? 'Sign out' : 'Sign In';
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });
  
  useEffect(() => {
    const signin = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? 'Sign out' : 'Sign In';
    setSignInDetails(signin);
    const redirectUrl = window.location.pathname.split('/')[1];
    if (
      signin === 'Sign In' &&
      ['my-orders', 'my-favourite', 'shipping-address', 'my-profile', 'track-orders'].includes(redirectUrl)
    ) {
      localStorage.setItem('showSignInPopup', 'true');
      navigate("/");
    }

  }, [signin]);

  useEffect(() => {
    const showPopup = localStorage.getItem('showSignInPopup');
    if(showPopup){
      setLgShow(true);
      setURL("/");
    }else{
      setLgShow(false);
    }
    setTimeout(() => {
      localStorage.removeItem('showSignInPopup')
    }, 1000);
    localStorage.removeItem('userid')

  }, []);


  useEffect(() => {
    const signin = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? 'Sign out' : 'Sign In';
    setSignInDetails(signin);
    navigator.geolocation.getCurrentPosition(pos => {
      const { latitude, longitude } = pos.coords;
      const apiKey = 'pk.839eecc5a00a36d35a88b499ff6e5a9f';
      // const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      const url = `https://us1.locationiq.com/v1/reverse.php?key=${apiKey}&lat=${latitude}&lon=${longitude}&format=json`;
      fetch(url).then(res => res.json()).then(data => {

        setPincode(data.address.postcode)
        // console.log('pincode....', data)
        const showLocation = localStorage.getItem('locationShow');
        localStorage.setItem('pincodeCkeck',false);
        // console.log('showLocationsdasdas',showLocation);
        
        if(showLocation ==null){
          setShow(true)
          localStorage.setItem('locationShow',true);
          localStorage.setItem('pincodeCkeck',true);
        }
      })
    })
    // setLgShow(false)
    }
    , [])

  useEffect(() => {
    getcartCounts();
    if (params.signInDetails && params.page == 'cart') {
      setSignInDetails(params.signInDetails);
    }

  }, [params.cartCount, params.signInDetails])

  useEffect(() => {
    if (cookies.LOGGEDINFO !== ''&& cookies.LOGGEDINFO!== undefined) {
    // if (signInDetails == 'Sign out') {
      userValidation();
    }
  }, [params.userData, userId])

  const userValidation = () => {
    userValidate(userId)
      .then(response => {
        setUserData(response.data.userId)
        if (response.data.userId === '') {
          setCount(params.cartCount)
          showModal()
          removeCookie('LOGGEDINFO')
          setSignInDetails('Sign In')
        }
      })
  };

  const showModal = () => {
    setModal({ show: true, type: 'success', title: 'Account Suspended', message: 'Your account has been Suspended. Please contact the Admin.' });
  }

  const fetchData = () => {
    generalData().then(response => {
      if (response.site_details.length > 0) {
        setGeneralData(response.site_details[0]);
        localStorage.setItem('GeneralData', JSON.stringify(response.site_details[0]))
      }
    })
    getCategoryList().then(response => {
      setCategories(response.data.data);
      const searchkey = window.location.href.split('/');
      if (searchkey[4] != undefined) {
        if (searchkey[3] === 'product-detail') {
          setSearchKey('');
        }
        else if (searchkey[3] === 'order-success' || searchkey[3] === 'today-special' ||searchkey[3] === 'category') {
          setSearchKey('');
        }
        else {
          setSearchKey(searchKey === id ? searchKey : id)
          // setSearchKey(searchkey[4]);
        }
        // response.data.data.map((data) => {
        //   console.log('search key load', data.category_slug,  searchkey[4])
        //   if (data.category_slug != searchkey[4]) {
        //     getSearchproduct(searchkey[4], userId, usertype, '', '', 1)
        //       .then(response => {
        //         setSearchResults(response.data.searchproducts || []);
        //         setIsLoading(false)
        //       })
        //   }
        //   else {
        //     setSearchKey('');
        //   }
        // })
      }
    })
  }
  
  const searchInput=()=>{
    getProducts(searchKey, '')

    if(searchKey!=""){
      setOpen(true)
    }else{
      setOpen(false)
    }
  }

  useEffect(() => {
    fetchData()
    if (cookies.SESSIONINFO == undefined) {
      const unique_id = uuid();
      setCookie('SESSIONINFO', JSON.stringify(unique_id));
    }
  }, [])

  const LogoutUser = () => {
    setModal({ show: true, type: 'confirmation', title: 'Confirmation', message: 'Are you sure want to Sign out' });
  }

  const onCloseModel = (type) => {
    if (type == 1) {
      if (urlData[3] == '') {
        // console.log('urldata', urlData)
        removeCookie('LOGGEDINFO', { path: '/' })
        // console.log('signidetail', signInDetails)
        localStorage.removeItem('userid');
        setLgShow(false)
        setSignInDetails('Sign In')
        window.location.reload();
      }
      else {
        setSignInDetails('Sign In')
        // console.log('signidetail', signInDetails)
        navigate("/")
        removeCookie('LOGGEDINFO', { path: '/' })
        localStorage.removeItem('userid');
        setLgShow(false)
      }
    }
  };

  const getcartCounts = () => {
    getCartCount(userId, usertype).then(response => {
      setCartCount(response)
    })
  }

  const getProducts = (searchKey, searchList) => {
    getSearchproduct(searchKey, userId, usertype, searchList, '', 1).then(response => {
      setSearchResults(response.data.searchproducts || []);
      setIsLoading(false)
    })

  }

  const handleOutsideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setOpen(false)
    }
  };


  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchKey(value);
    if (value != '') {
      setOpen(true)
    }
    if (value === '') {
      setOpen(false)
    }
    setIsLoading(true)
    getProducts(value, '')

  };

  const viewMore = () => {
    if (searchKey != '') {
      getProducts(searchKey, '1');
      localStorage.removeItem('searchitem')
      setOpen(false)
    }

  }
  
  const handleKeyPress = (event) => {

    if (event.key === 'Enter') {
      if (highlightedIndex !== -1 && searchResults.length > 0) {
        const selectedProduct = searchResults[highlightedIndex];
        navigate(`/product-detail/${selectedProduct.product_slug}`, { state: { product: selectedProduct } });
      }else{
        navigate((searchKey != '') ? `/search/${searchKey}` : '')
      }
      localStorage.removeItem('searchitem')
      setOpen(false)
    }
     else if (event.key === 'ArrowDown') {
      setHighlightedIndex((prevIndex) =>
        prevIndex < 5 - 1 ? prevIndex + 1 : -1
      );
    } else if (event.key === 'ArrowUp') {
      setHighlightedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : -1
      );
    }
  };

  const isAuthenticated = (data) => {
    if (signInDetails == 'Sign In') {
      setLgShow(true);
      setURL(data);
    }
    else {
      //removeCookie('LOGGEDINFO')
      navigate(data)
    }
  };

  const mobileViewAuthenticate = () => {
    if (signInDetails == 'Sign In') {
      setLgShow(true);
      setURL("/my-profile");
    }
    else {
      navigate("/my-profile");
    }
  }

  const PageTypeGet = (data) => {
    CMSRequest(data).then(response => {
      localStorage.removeItem('cmsData');
      localStorage.setItem('cmsData', JSON.stringify(response.data));
      if (response.data != null) {
        if (data === 'about_us') {
          navigate('/about-us')
        }
        if (data === 'why_us') {
          navigate('/why-us')
        }
      }
    })
  };
  // account
  const showDropdownin = (e) => {
    setShowin(!showin);
  }
  const hideDropdownin = e => {
    setShowin(false);
  }

  const getCategoryListData = () => {
    return (
      categories.map((category) => (
        <Link key={category.category_slug} to={`/category/${category.category_slug}`} state={category.category_slug} className="dropdown-item drop-left">
          {category.category_name}
        </Link>
      ))
    )
  }
  //  category
  const showDropdownout = (e) => {
    setShowout(!showout);
  }

  const hideDropdownout = e => {
    setShowout(false);
  }

  return (
    <header id="remove-top" className="header-top fixed-top" ref={newRef}>
      <div className="header">
        <Navbar expand="lg">
          <Container>
            {/* <Link to="/" className="navbar-brand"><img src="../assets/images/logo.png" alt="" /></Link> */}
            <div className="d-lg-block mail-box">
              <p className="nav-link cart-link-mob mb-0">
                <span className="iconify">
                  <Icon icon="carbon:email" />
                </span>
                {GeneralData.contact_email}
                {/* customercare@chotekisan.com */}
              </p>
            </div>
            <div className="d-lg-block mail-box">
              <div className="nav-link cart-link-mob">

              </div>
            </div>
            <div
              className="location-box location-mobile-view"
              onClick={handleShow}
            >
              <span className="iconify">
                <Icon icon="fluent:location-48-regular" />
              </span>
              <NavDropdown
                title={`Your location ${pinCode !== "" ? ':' : ''} ${pinCode}`}
                id="collasible-nav-dropdown"
              >
                {/* <Link to="/Product/Detail" className="dropdown-item">test</Link>
								<Link to="/Product/Detail" className="dropdown-item">test</Link>
								<Link to="/Product/Detail" className="dropdown-item">test</Link> */}
              </NavDropdown>
              <span className="iconify-arrow-header">
                <Icon icon="iconamoon:arrow-down-2-light" />
              </span>
            </div>
            <Link to="/" className="navbar-brand navbar-logo">
              <img src="../assets/images/logo.gif" alt="" />
            </Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto">
                <button className="nav-link" onClick={() => { PageTypeGet('about_us') }} >About Us</button>
                <button className="nav-link" onClick={() => { PageTypeGet('why_us') }} >Why Us</button>
                <Link to="/testimonials" className="nav-link">
                  Testimonials
                </Link>
                <Link to="/Faq" className="nav-link">
                  FAQ
                </Link>
              </Nav>
              {/* <Link to="/" className="nav-link active">Home</Link> */}
              <div className="location-box" onClick={handleShow}>
                <span className="iconify">
                  <Icon icon="fluent:location-48-regular" />
                </span>
                <NavDropdown
                  title={`Your location ${pinCode !== "" ? ':' : ''} ${pinCode}`}
                  id="collasible-nav-dropdown"
                ></NavDropdown>
                <span className="arrow-header">
                  <Icon icon="iconamoon:arrow-down-2-light" />
                </span>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="header-bottom">
          <Container>
            <Row className="d-flex align-items-center  gx-3">
              <Col lg={2} md={2} xs={3}>
                <Link to="/" className="navbar-brand">
                  <img src="../assets/images/logo.gif" alt="" />
                </Link>
              </Col>
              <Col lg={6} md={3} xs={9} className="cols-search">
                <div className="category-flex">
                  <div className="account-box header-category" >
                    {/* <span className="iconify menu-icon">
                      <Icon icon="entypo:menu" />
                    </span> */}
                    <NavDropdown
                      className="dropdown-box"
                      title="Category"
                      id="collasible-nav-dropdown"
                      show={showout}
                      onMouseEnter={showDropdownout}
                      onMouseLeave={hideDropdownout} renderMenuOnMount={true}
                    >
                      {getCategoryListData()}
                    </NavDropdown>
                    <span className="iconify-arrow-left list-arrow">
                      <Icon icon="iconamoon:arrow-down-2-light" />
                    </span>
                  </div>
                  <div className="search-cart">
                    <div className="form-group search-icon">
                      <Link onClick={viewMore} to={(searchKey != '') ? `/search/${searchKey}` : ''} state={{ searchKey }} >
                        <span className="iconify">
                          <Icon icon="iconamoon:search-light" />
                        </span>
                      </Link>

                      <InputGroup className="mb-3 search-bar">
                        <Form.Control
                          placeholder="Search from our fresh products"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={searchKey}
                          onChange={handleSearchChange}
                          onKeyDown={handleKeyPress}
                          onClick={() => (searchInput())}
                        />
                      </InputGroup>
                      {(isLoading == true && searchKey != '') ? <div className="auto-box">
                        <SkeletonLoader loadertype={'search'} />
                      </div> : (open) ? <><div className="auto-box" /* onclick={myFunction} */>
                        <div className="auto-list">
                          <ul>
                            {(searchResults.length > 0 && searchKey != '') ? searchResults.slice(0, 5).map((product, index) => (
                              <li key={index} className={highlightedIndex === index ? 'highlighted' : ''}  /* onMouseEnter={() => setHighlightedIndex(index)} */>
                                <Link to={`/product-detail/${product.product_slug}`} state={{ product }}>
                                  <div className="auto-img">
                                    <img
                                      src={product.product_image}
                                      alt=""
                                      title=""
                                    />
                                  </div>
                                  {product.product_name} / {product.product_name2} - {product.variant_name + ' ' + product.nic_name}
                                </Link>
                              </li>
                            )) : <li><div className="no-result">No Result Found</div></li>}
                          </ul>
                        </div>
                        <div className="view-btn search-view">
                          {(searchResults.length > 5) && <Link onClick={viewMore} to={`/search/${searchKey}`} state={{ searchKey }} >
                            VIEW MORE
                          </Link>}

                        </div>
                      </div></> : ''}
                    </div>
                    <div className="user-account">
                      <button onClick={() => mobileViewAuthenticate()} >
                        <span
                          id="collasible-nav-dropdown dropdown-toggle show nav-link"
                          className="iconify"
                        >
                          <Icon icon="ph:user" />
                        </span>
                      </button>
                      {/* <NavDropdown
                      className="dropdown-box"
                      title=""
                      id="collasible-nav-dropdown"
                    >
                      <Link to="/my-orders" className="dropdown-item">
                        <span className="iconify-drop">
                          <Icon icon="lets-icons:order" />
                        </span>
                        My Orders
                      </Link>
                      <Link to="/shipping-address" className="dropdown-item">
                        <span className="iconify-drop">
                          <Icon icon="ph:address-book-light" />
                        </span>
                        My Addresses
                      </Link>
                      <Link to="/my-favourite" className="dropdown-item">
                        <span className="iconify-drop">
                          <Icon icon="mdi:favourite-border" />
                        </span>
                        My Favourites
                      </Link>
                      <Link to="my-profile" className="dropdown-item">
                        <span className="iconify-drop">
                          <Icon icon="iconamoon:profile" />
                        </span>
                        My Profile
                      </Link>
                      <Link
                        onClick={() => setLgShow(true)}
                        to="/"
                        className="dropdown-item"
                      >
                        <span className="iconify-drop">
                          <Icon icon="tabler:logout" />
                        </span>
                        Sign Out
                      </Link>
                    </NavDropdown> */}
                    </div>
                    <div className="account-cart" >
                      <Link to="/Cart">
                        <span className="cart-count">{cartCount}</span>
                        <span className="iconify">
                          <Icon icon="solar:cart-4-linear" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={2} md={3} xs={6}>
                <div className="place-order order-box">

                  <span className="iconify-call">
                    <Icon icon="ph:phone-call" />
                  </span>
                  {/* <span className="iconify-eye">
                    <Icon icon="ri:information-fill" />
                  </span> */}
                  <p>Call & Place Orders <Link >
                  </Link></p>
                  {/* <span>{GeneralData.contact_number}</span> */}
                  <Link to={`tel:${GeneralData.contact_number}`}> <span>{GeneralData.contact_number}</span>
                  </Link>

                </div>
              </Col>
              <Col lg={2} md={4} xs={6} className="cols-account">
                <div className="card-box">
                  <ul>
                    {(signInDetails == 'Sign In') ? <button onClick={() => isAuthenticated("/")}>
                      <span className="iconify-drop">
                        <Icon icon="ph:user" />
                      </span>
                      Login
                    </button> : <><li>
                      <div className="account-box" >
                        <span className="iconify">
                          <Icon icon="ph:user" />
                        </span>
                        <NavDropdown
                          className="dropdown-box"
                          title="My Account"
                          id="collasible-nav-dropdown"
                          show={showin}
                          onMouseEnter={showDropdownin}
                          onMouseLeave={hideDropdownin} renderMenuOnMount={true}
                        >
                          {/* <div className=""></div>*/}

                          <button className="dropdown-item" onClick={() => isAuthenticated("/my-orders")}>
                            <span className="iconify-drop">
                              <Icon icon="lets-icons:order" />
                            </span>
                            My Orders
                          </button>
                          <button className="dropdown-item" onClick={() => isAuthenticated("/shipping-address")}>
                            <span className="iconify-drop">
                              <Icon icon="ph:address-book-light" />
                            </span>
                            My Addresses
                          </button>
                          <button className="dropdown-item" onClick={() => isAuthenticated("/my-favourite")}  >
                            <span className="iconify-drop">
                              <Icon icon="mdi:favourite-border" />
                            </span>
                            My Favourites
                          </button>
                          <button className="dropdown-item" onClick={() => isAuthenticated("/my-profile")}>
                            <span className="iconify-drop">
                              <Icon icon="iconamoon:profile" />
                            </span>
                            My Profile
                          </button>
                          <button onClick={() => { LogoutUser() }} className="dropdown-item" ><span className="iconify-drop"><Icon icon={"tabler:login"} /></span>{signInDetails}</button>
                        </NavDropdown>
                        <span className="iconify-arrow-left">
                          <Icon icon="iconamoon:arrow-down-2-light" />
                        </span>
                      </div>
                    </li></>}
                    <li>
                      <div
                        className="account-cart"
                      >
                        <Link to={'/cart'}>
                          <span className="cart-count">{cartCount}</span>
                          <span className="iconify">
                            <Icon icon="solar:cart-4-linear" />
                          </span>
                          My Cart
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      {/* modal */}
      <YourLocation show={show} setShow={setShow} pinCode={pinCode} />
      {/* modal3 */}
      <SignIn lgShow={lgShow} setLgShow={setLgShow} signInDetails={signInDetails} setSignInDetails={setSignInDetails} url={url} />

      <Modal
        size="lg"
        //    show={xlShow}
        //    onHide={() => setxlShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="popup-box">
          <Row>
            <Col lg={6} md={3} xs={6}>
              <div className="delivery-box text-start">
                <h4>
                  Get started to
                  <br />
                  support our farmers
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur <br />
                  adipiscing elit, sed do eiusmod tempor
                </p>
                <div className="popup-img">
                  <img src="../assets/images/bag-2.svg" alt="" />
                </div>
              </div>
            </Col>
            <Col lg={6} md={3} xs={12}>
              <div className="location-modal">
                <div className="logo-point">
                  <img src="../assets/images/logo.svg" alt="" />
                </div>
                <Modal.Header className="btn-head" closeButton>
                  <div className="header-modal">
                    <h4>Sign In</h4>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-modal">
                    <p>Sign In your account to get started!</p>
                    <Row>
                      <Col lg={12} md={12} xs={12}>
                        <div className="form-location">
                          <Form className="mt-2">
                            <Form.Group
                              className="form-group mb-4"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="label-text">
                                Mobile / Email ID
                              </Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="enter mobile or email id"
                              />
                            </Form.Group>
                          </Form>

                          <Button
                            variant="primary"
                            to="/Home"
                            className="btn-lg w-100 home-btn"
                            type="submit"
                          >
                            Sign Up
                          </Button>
                          <p className="account-sign mt-2">
                            I didn’t have account ?
                            <span className="text-green">
                              <Link> Sign Up.</Link>
                            </span>
                          </p>
                        </div>
                        <div className="skip-btn" onClick={handleClose}>
                          {/* <Link   to="" closeButton>Skip</Link> */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Modal.Body>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      <ModalWindow data={modalData} onCloseModel={onCloseModel} />
    </header >
  );
};

export default Header;
