import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { getTestimonial } from "../Services/serviceApi";

const Testimonials = () => {
  const carouselOptions = {
    loop: true,
    margin: 10,
    responsive: {
      0: {
        items: 1, // Two item on mobile
      },
      1024: {
        items: 3, // Three item on desktop
      },
      767: {
        items: 3, // Four items on tab
      },
    },
  };

  const [pageContent, setPageContent] = useState([]);

  const getTestimonialData = () => {
    getTestimonial()
      .then(response => {
        setPageContent(response.data)
      })
  }

  const getTestimonials = () => {
    const content = [];
      pageContent.map((data) => {
        content.push(
          <Col lg={4} md={4} xs={12}>
            <div className="testimonials-box">
              <div className="quot-box">
                <span className="iconify">
                  <Icon icon="ri:double-quotes-l" />
                </span>
              </div>
              <div className="testi-box">
              <p>
                {data.comments}
              </p>
              </div>
              <div className="people-details">
                <div className="testi-img">
                  <img src="../assets/images/works-img1.jpg" alt="" />
                </div>
                <div className="testi-right">
                  <h5>{data.testimonial_name}</h5>
                  <p>
                    <strong className="text-success">{data.testimonial_designation}</strong>
                  </p>
                  {/* <p>Coimbatore</p> */}
                </div>
              </div>
            </div>
          </Col>
        )
      })
    return content;

  }

  useEffect(() => {
    getTestimonialData();
  }, [])

  return (
    <div>
      <Header />
      <section className="order-success mt-space">
        <Container>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to='/'>
                  Home
                  <span className="iconify">
                    <Icon icon="iconamoon:arrow-right-2-duotone" />
                  </span>
                </Link>
              </li>
              <li className="active">Testimonials</li>
            </ul>
          </div>
          <div className="product-heading">
            <h3>Testimonials</h3>
          </div>

          <Row>
            {getTestimonials()}
            {/* <Col lg={4} md={4} xs={12}>
              <div className="testimonials-box para-text">
                <div className="quot-box">
                  <span className="iconify">
                    <Icon icon="ri:double-quotes-l" />
                  </span>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy
                  text ever since the 1500s, when an unknown printer took a galley
                  of type and scrambled it to make a type specimen book.
                </p>

                <div className="people-details">
                  <div className="testi-img">
                    <img src="../assets/images/works-img1.jpg" alt="" />
                  </div>
                  <div className="testi-right">
                    <h5>mr.Kanda Saamy</h5>
                    <p>
                      <strong className="text-success">choté kisan</strong>
                    </p>
                    <p>Coimbatore</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} xs={12}>
              <div className="testimonials-box para-text">
                <div className="quot-box">
                  <span className="iconify">
                    <Icon icon="ri:double-quotes-l" />
                  </span>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy
                  text ever since the 1500s, when an unknown printer took a galley
                  of type and scrambled it to make a type specimen book.
                </p>

                <div className="people-details">
                  <div className="testi-img">
                    <img src="../assets/images/works-img1.jpg" alt="" />
                  </div>
                  <div className="testi-right">
                    <h5>mr.Kanda Saamy</h5>
                    <p>
                      <strong className="text-success">choté kisan</strong>
                    </p>
                    <p>Coimbatore</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} xs={12}>
              <div className="testimonials-box para-text">
                <div className="quot-box">
                  <span className="iconify">
                    <Icon icon="ri:double-quotes-l" />
                  </span>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy
                  text ever since the 1500s, when an unknown printer took a galley
                  of type and scrambled it to make a type specimen book.
                </p>

                <div className="people-details">
                  <div className="testi-img">
                    <img src="../assets/images/works-img1.jpg" alt="" />
                  </div>
                  <div className="testi-right">
                    <h5>mr.Kanda Saamy</h5>
                    <p>
                      <strong className="text-success">choté kisan</strong>
                    </p>
                    <p>Coimbatore</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} xs={12}>
              <div className="testimonials-box para-text">
                <div className="quot-box">
                  <span className="iconify">
                    <Icon icon="ri:double-quotes-l" />
                  </span>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy
                  text ever since the 1500s, when an unknown printer took a galley
                  of type and scrambled it to make a type specimen book.
                </p>

                <div className="people-details">
                  <div className="testi-img">
                    <img src="../assets/images/works-img1.jpg" alt="" />
                  </div>
                  <div className="testi-right">
                    <h5>mr.Kanda Saamy</h5>
                    <p>
                      <strong className="text-success">choté kisan</strong>
                    </p>
                    <p>Coimbatore</p>
                  </div>
                </div>
              </div>
            </Col> */}
          </Row>






        </Container>
      </section>

      <Footer />
    </div>
  );
};

export default Testimonials;
