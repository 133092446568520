import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Icon } from "@iconify/react";
/* import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion"; */

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";

const AboutUs = () => {
  const [pageContent,setPageContent] = useState([]);

  useEffect(()=>{
    const content = localStorage.getItem('cmsData');
    const content1 = JSON.parse(content)
    if(content1 !=null){
      setPageContent(content1)
    }
  },[])

   const getAboutUsContent = () => {
    const content = [];
    if (pageContent != null && pageContent.length > 0) {
      pageContent.forEach((data, index) => {
        content.push(<div dangerouslySetInnerHTML={{ __html: data.description}} />);
      });
      return content;
    }
  };

 /* const getAboutUsContent = () =>{
    const content = [];
    if(pageContent !=null && pageContent.length > 0){
      pageContent.map((data)=>{
        content.push(
          <Col lg={3} md={3} xs={6}>
            <div dangerouslySetInnerHTML={createMarkup(data.description)} />
            {/* <Link to="/">
              <div className="works-box">
                {data.description}
                <div className="steps">01</div>
                <div className="works-img">
                  <img src="assets/images/works-img1.jpg" alt="" title="" />
                </div>
                <p>
                  We encourage <br /> our <span>choté kisans</span>
                </p>
              </div>
            </Link>
          </Col>
        )
      })
      return content;
    }
  } */

  return (
    <div>
      <Header />
      <section className="order-success mt-space">
        <Container>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to ='/'>
                  Home
                  <span className="iconify">
                    <Icon icon="iconamoon:arrow-right-2-duotone" />
                  </span>
                </Link>
              </li>
              {(pageContent !=null && pageContent.length > 0) && <li className="active">{pageContent[0].page_name}</li>}
            </ul>
          </div>

          <div className="product-heading">
            {(pageContent !=null && pageContent.length > 0) && <h3>
             {pageContent[0].title}
             About choté kisan
            </h3>}
          </div>
          {(pageContent !=null && pageContent.length > 0) && <div dangerouslySetInnerHTML={{ __html: pageContent[0].description}} onClick={(e)=>{e.preventDefault()}}/>}
          <Row>
          {/* {getAboutUsContent()} */}
          </Row>
          {/* <Row>
            <Col lg={3} md={3} xs={6}>
              <Link to="/">
                <div className="works-box">
                  <div className="steps">01</div>
                  <div className="works-img">
                    <img src="assets/images/works-img1.jpg" alt="" title="" />
                  </div>
                  <p>
                    We encourage <br /> our <span>choté kisans</span>
                  </p>
                </div>
              </Link>
            </Col>
            <Col lg={3} md={3} xs={6}>
              <Link>
                <div className="works-box">
                  <div className="steps">02</div>
                  <div className="works-img">
                    <img src="assets/images/works-img2.jpg" alt="" title="" />
                  </div>
                  <p>
                    We directly purchase <br /> from <span>choté kisans</span>
                  </p>
                </div>
              </Link>
            </Col>
            <Col lg={3} md={3} xs={6}>
              <Link>
                <div className="works-box">
                  <div className="steps">03</div>
                  <div className="works-img">
                    <img src="assets/images/works-img3.jpg" alt="" title="" />
                  </div>
                  <p>
                    And we deliver the <br /> products to customers
                  </p>
                </div>
              </Link>
            </Col>
            <Col lg={3} md={3} xs={6}>
              <Link>
                <div className="works-box">
                  <div className="steps">04</div>
                  <div className="works-img">
                    <img src="assets/images/works-img4.jpg" alt="" title="" />
                  </div>
                  <p>
                    Our <span>choté kisans</span> <br /> get benefits every day
                  </p>
                </div>
              </Link>
            </Col>
          </Row> */}
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default AboutUs;
