import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import InputGroup from "react-bootstrap/InputGroup";
/* import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion"; */

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
//import ProfileSidemenu from "../Components/ProfileSidemenu";
import ReCAPTCHA from "react-google-recaptcha";
import { ContactUsDetailsSend } from "../Services/serviceApi";

const ContactUs = () => {

  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('')
  const [messages, setMessages] = useState('');
  const defaultData = { fullName: '', phoneNumber: '', email: '', messages: '', captchaStatus: '' };
  const [formErrors, formError] = useState(defaultData);
  const [responseResult, setResponseResult] = useState({});
  const [generalData, setGeneralData] = useState([])
  const [captchaStatus, setCaptchaStatus] = useState('');
  const captchaRef = React.createRef();
  const siteKey = '6Lf_gDQqAAAAAFcIxt0Kv6b2vYPNwlxzO5sL1M3w';

  const fetchData = () => {
    const data = localStorage.getItem('GeneralData');
    if (data != null) {
      const data1 = JSON.parse(data);
      setGeneralData(data1)
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  const validateName = (name) => {
    return /^(?!^\d+$)[a-zA-Z0-9]{2,50}$/i.test(name);
  };

  const validateMobile = (mobile) => {
    return /^[6789][0-9]{9}$/i.test(mobile);
    //return regex.test(mobile);
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9.]+@[a-zA-Z]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  };

  const onChange = (e) => {
    const error = {};
    const eTarget = e.target;
    const { value } = e.target;
    const re = /^[0-9]*$/;
    // eslint-disable-next-line default-case
    switch (eTarget.name) {
      case 'fullName':
        if(eTarget.value.trim() && !validateName(eTarget.value)){
          formError({ ...error, [eTarget.name]: "Invalid user name" });
        }else{
          formError({ ...error, [eTarget.name]: "" });
        }
        setFullName(eTarget.value)
        break;
      case 'phoneNumber':
        if (eTarget.value.trim() && !validateMobile(eTarget.value)) {
          formError({ ...error, [eTarget.name]: "Invalid Mobile number" });
        } else {
          formError({ ...error, [eTarget.name]: "" });
        }
        if (value === "" || re.test(value))
          setPhoneNumber(eTarget.value)
        break;
      case 'email':
        if (eTarget.value.trim() && !validateEmail(eTarget.value)) {
          formError({ ...error, [eTarget.name]: "Invalid Email Address" })
        } else {
          formError({ ...error, [eTarget.name]: "" });
        }
        setEmail(eTarget.value)
        break;
      case 'messages':
        setMessages(eTarget.value)
        break;
    }
  };

  const onChangeCaptcha = (value)=>{
    if (value) {
      setCaptchaStatus(value);
    }
  }

  const submitContactDetails = () => {
    const error = {};
    if (fullName === '' || fullName == null)
      error.fullName = 'Enter Full Name';
    else if (fullName.length < 3) {
      error.fullName = "Invalid user name";
    }else if(!validateName(fullName)){
      error.fullName = 'Invalid user name';
    }
    if (email === '' || email == null)
      error.email = 'Enter Email';
    else if(!validateEmail(email)){
      error.email = 'Invalid Email Address';
    }
    if (phoneNumber === '' || phoneNumber == null)
      error.phoneNumber = 'Enter Mobile number';
    else if (!validateMobile(phoneNumber))
      error.phoneNumber = 'Invalid Mobile number'
    if(captchaStatus ==='' || captchaStatus == null)
    error.captchaStatus = 'Please click the above field';

    if (Object.keys(error).length == 0) {
      ContactUsDetailsSend(fullName, phoneNumber, email, messages)
        .then(response => {
          setResponseResult(response.message);
          setFullName('');
          setPhoneNumber('');
          setEmail('');
          setMessages('');
          setTimeout(() => {
            setResponseResult('')
          }, 3000)
        })
    }
    formError(error)
  }

  return (
    <div>
      <Header />
      <section className="order-success mt-space">
        <Container>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to='/'>
                  Home
                  <span className="iconify">
                    <Icon icon="iconamoon:arrow-right-2-duotone" />
                  </span>
                </Link>
              </li>
              <li className="active">Contact Us</li>
            </ul>
          </div>
          <div className="contact-box text-center">
            <h4>Have Some Question?</h4>
            <p>
              Thank you for your interest in our services. Please fill out the
              form below or e-mail us <br /> at
              <Link className="text-success" to={`mailto:${generalData.contact_email}`}>
                <strong> {generalData.contact_email} </strong>
              </Link>
              and we will get back to you promptly regarding your request.
            </p>
          </div>
          <Row className="d-flex justify-content-center">
            <Col lg={4} md={6} xs={12}>
              <div className="contact-left">
                <div className="contact-img">
                  <img src="../assets/images/contact.svg" alt="Help" title="Contact" />
                </div>
                <div className="contact-list">
                  <h5>Get in touch</h5>
                  <ul>
                    <li>
                      <span className="iconify-icon">
                        <Icon icon="material-symbols:call" />
                      </span>
                      <Link to={`tel:${generalData.contact_number}`} >{generalData.contact_number}</Link>
                    </li>
                    <li>
                      <span className="iconify-icon">
                        <Icon icon="mingcute:whatsapp-fill" />
                      </span>
                      <Link to="//wa.me/9444082985" target="blank" >+91 944 408 2985</Link>
                    </li>
                    <li>
                      <span className="iconify-icon">
                        <Icon icon="mdi:location" />
                      </span>
                      {generalData.address}
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} xs={12}>
              <div className="contact-right">
                <Row>
                  <Col lg={12} md={12} xs={12}>
                    <div className="form-location inputbt-space">
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="label-text">
                          Full Name <b>*</b>
                          {formErrors.fullName != '' && formErrors.fullName != undefined && (<Form.Text className="error text-danger errortext">{formErrors.fullName}</Form.Text>)}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="enter your full name"
                          name='fullName'
                          value={fullName}
                          onChange={onChange}
                        />
                      </Form.Group>
                    </div>
                  </Col>

                  <Col lg={12} md={12} xs={12}>
                    <div className="form-location inputbt-space">
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="label-text">Phone <b>*</b>{formErrors.phoneNumber != '' && formErrors.phoneNumber != undefined && (<Form.Text className="error text-danger errortext">{formErrors.phoneNumber}</Form.Text>)}</Form.Label>
                        <Form.Control type="text" placeholder="enter your phone number" name='phoneNumber' value={phoneNumber} onChange={onChange} maxLength='10' required />

                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={12} md={12} xs={12}>
                    <div className="form-location inputbt-space">
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="label-text">Email ID <b>*</b>{formErrors.email != '' && formErrors.email != undefined && (<Form.Text className="error text-danger errortext">{formErrors.email}</Form.Text>)}</Form.Label>
                        <Form.Control type="email" placeholder="email id" name='email' value={email} onChange={onChange} />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={12} md={12} xs={12}>
                    <div className="form-location input-textarea">
                      <Form.Label className="label-text">Message</Form.Label>
                      <InputGroup>
                        <Form.Control as="textarea" aria-label="enter landmark" placeholder="type here" name='messages' value={messages} onChange={onChange} />
                      </InputGroup>
                    </div>
                  </Col>
                  <Col lg={12} md={12} xs={12}>
                    <div className='captchabox mt-3'>
                       { <ReCAPTCHA
                      //  src="https://www.google.com/recaptcha/enterprise.js?render=6LejSjQqAAAAAH23cSMmLl-Pem6XFPRqZ86sGWLY"
 
                       //live & local same site key
                        sitekey={siteKey}
                        //sitekey="6LcTqlwnAAAAAFVpSi4KS1fQ4p8Jfsf9e6PoGoKD"
                        ref={captchaRef}
                        onChange={onChangeCaptcha}
                      />}
 
                      {formErrors.captchaStatus!=='' && formErrors.captchaStatus!==undefined && (<Form.Text className='error text-danger'>{formErrors.captchaStatus}</Form.Text>)}
                    </div>
                  </Col>
                  <Col lg={12} md={12} xs={12}>
                    <div className="add-button">
                      <Button
                        variant="primary"
                        to="/Home"
                        className="btn-lg w-100 btn-submit btn-size mt-3"
                        type="submit"
                        onClick={submitContactDetails}
                      >
                        Send Message
                      </Button>
                      {(Object.keys(responseResult).length > 0) && <p className="text-success">{responseResult}</p>}
                    </div>

                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </div>
  );
};

export default ContactUs;
