import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Carousel from "react-bootstrap/Carousel";

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { getCategoryList,getBannerList } from "../Services/serviceApi";

const Categories = () => {

    const [categories, setCategories] = useState([]);
    const [banners, setBanners] = useState([]);

    const carouselOptions = {
        loop: true,
        margin: 10,
        responsive: {
            0: {
                items: 1, // Two item on mobile
            },
            1024: {
                items: 3, // Three item on desktop
            },
            767: {
                items: 3, // Four items on tab
            },
        },
    };

    const getCategories = () => {
        getCategoryList().then(response => {
            setCategories(response.data.data)
        })
    }

    useEffect(() => {
        getCategories();
        getBanners()
    }, [])

    const getBanners = () => {
        getBannerList().then(response => {
            setBanners(response.data.banners);
        })
        return [banners];
    }

    const getCarousalItems = () => {
        const content = [];
        banners.map((banner, index) => (
            content.push(<Carousel.Item key={index}>
            {/* <Link> */}
                <img src={banner.banner_img} alt="" />
            {/* </Link> */}
            </Carousel.Item>
        )
        ))
        return content;
    }

    return (
        <div>
            <Header />
            <section className="slider pt-space mt-space mainSlider">
                <Container>
                    <div className="breadcrumb">
                        <ul>
                            <li>
                                <Link to='/'>
                                    Home
                                    <span className="iconify">
                                        <Icon icon="iconamoon:arrow-right-2-duotone" />
                                    </span>
                                </Link>
                            </li>
                            <li className="active">Categories</li>
                        </ul>
                    </div>
                    <div className="banner-slider">
                        <Carousel>
                        {(banners != undefined && banners.length > 0) ? getCarousalItems() : <div className="skeleton"></div>}
                        </Carousel>
                    </div>
                </Container>
            </section>

            <section className="Category-list pt-space">
                <div className="product-heading">
                    <h3>Shop by Categories</h3>
                </div>
                {(categories != undefined && categories.length === 0) ?
                    <div className="empty-text"><p>Sorry, no categories available at the moment. Please check back later</p></div>
                    : <div className="category-type">
                        <Container>
                            <Row>
                                {categories.map((category, index) => (
                                    <Col lg={4} md={4} xs={12} key={index}>
                                        {/* <Link to={`/product-list?cat_id=${btoa(category.id)}`}> */}
                                        <Link to={`/category/${category.category_slug}`} state={category.category_slug}>
                                            <div className={`category-box ${index%2 !=0? 'bg-orange' : 'bg-green'} `}>
                                                <div className="Category-title">
                                                    <span>FRESH</span>
                                                    <h3>{category.category_name}</h3>
                                                    <div className={`shop-btn ${index%2!=0 ? 'btn-orange' : 'btn-green'}  `}>SHOP NOW</div>
                                                </div>
                                                {/* <div className={`offer-box ${index === 1 ?'offer-orange':'offer-green'}`>
                        <span>
                          Min <br /> <strong>60%</strong> <br />
                          <span className="off-text">OFF</span>
                        </span>
                      </div> */}
                                                <div className={`fruits-img`}>
                                                    <img src={category.image} alt="" />
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                ))}
                            </Row>
                            {/* <div className="view-btn">
              <Link to="/ProductList" className="">
                VIEW ALL
              </Link>
          </div> */}
                        </Container>
                        {/* {categories && categories.length >3 ?
            <div className="view-btn view-btn-green" >
            <Link to={`/category/vegetables`} >
              VIEW ALL
            </Link>
          </div> 
          : ''} */}
                    </div>}
            </section>


            <Footer />
        </div>
    );
};

export default Categories;
