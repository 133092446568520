import React, { useState, useEffect } from "react";
import { Row, Col, Carousel, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { postLocationByPinCode } from "../Services/serviceApi";

const YourLocation = ({ show, setShow, pinCode, activated }) => {

  const [zipcode, setZipcode] = useState(pinCode);
  const [formErrors, formError] = useState({});
  const [resultMsg, setResultMsg] = useState({});
  const [isLoad, setIsLoad] = useState('Check');

  useEffect(() => {
    setZipcode(pinCode)
    const showLocation = localStorage.getItem('pincodeCkeck');
    if(showLocation == 'true'){
      postLocationByPinCode(pinCode).then(response => {
        setResultMsg(response.data)
        setIsLoad('Check')
      });      
    }
  }, [show])

  const onChange = (e) => {
    const re = /^[0-9]*$/;
    const { name, value } = e.target;
    if (name == "zipcode") {
      if (value === "" || re.test(value)) {
        setZipcode(value)
      }
    }
    
  };

  const submitPinCode = () => {
    const error = {};
    setResultMsg({});
    setIsLoad('Wait..')
    if (zipcode == '' || zipcode == null) {
      error.zipcode = 'Enter pincode';
      setIsLoad('Check')
    }
    else if (zipcode.length < 6) {
      error.zipcode = 'Enter valid pincode';
      setIsLoad('Check')
    }
    if (Object.keys(error).length == 0) {
      postLocationByPinCode(zipcode).then(response => {
        setResultMsg(response.data)
        setIsLoad('Check')
      });
    }
    formError(error)
  }

  const handleClose = () => {
    setShow(false);
    setZipcode('');
    setResultMsg({});
    formError({});
  }

  return (<Modal
    size="lg"
    show={show}
    onHide={handleClose}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <div className="popup-box">
      <Row className="d-flex align-items-center">
        <Col lg={6} md={6} xs={6}>
          <div className="banner-slider popup-banner">
            <Carousel>
              <Carousel.Item>
                <Link>
                  <div className="delivery-box">
                    <h4>
                      Free Home Delivery <br /> at your doorstep
                    </h4>
                    {/* <p>
                      Lorem ipsum dolor sit amet, consectetur <br />
                      adipiscing elit, sed do eiusmod tempor
                    </p> */}
                    <div className="slide-img">
                      <img src="../assets/images/delivery.gif" alt="" />
                    </div>
                  </div>
                </Link>
              </Carousel.Item>
              <Carousel.Item>
                <Link>
                  <div className="delivery-box">
                    <h4>
                      Free Home Delivery <br /> at your doorstep
                    </h4>
                    {/* <p>
                      Lorem ipsum dolor sit amet, consectetur <br />
                      adipiscing elit, sed do eiusmod tempor
                    </p> */}
                    <div className="slide-img">
                      <img src="../assets/images/delivery.gif" alt="" />
                    </div>
                  </div>
                </Link>
              </Carousel.Item>
              <Carousel.Item>
                <Link>
                  <div className="delivery-box">
                    <h4>
                      Free Home Delivery <br /> at your doorstep
                    </h4>
                    {/* <p>
                      Lorem ipsum dolor sit amet, consectetur <br />
                      adipiscing elit, sed do eiusmod tempor
                    </p> */}
                    <div className="slide-img">
                      <img src="../assets/images/delivery.gif" alt="" />
                    </div>
                  </div>
                </Link>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
        <Col lg={6} md={6} xs={12}>
          <div className="location-modal">
            <div className="logo-point">
              <img src="../assets/images/Logo_animation-2.gif" alt="" />
            </div>
            <Modal.Header className="btn-head" closeButton>
              <div className="header-modal">
                <h4>
                  Check availability for <br /> Home Delivery
                </h4>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="form-modal">
                <p>
                  Enter your location pincode to check <br />
                  availability.
                </p>
                <Row>
                  <Col lg={12} md={12} xs={12}>
                    <div className="form-location">
                      <Form className="mt-2">
                        <Form.Group
                          className="form-group mb-4"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className="label-text">
                            Location Pincode <b>* </b> {formErrors.zipcode != '' && formErrors.zipcode != undefined && (<Form.Text className="error text-danger errortext">{formErrors.zipcode}</Form.Text>)}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="6 digit number"
                            maxLength='6'
                            name='zipcode'
                            value={zipcode}
                            onChange={onChange}
                          />
                        </Form.Group>
                      </Form>
                      <button
                        variant="primary"
                        className="btn-lg w-100 home-btn"
                        type="submit"
                        onClick={() => { submitPinCode() }}
                      >
                        {isLoad}
                      </button>
                      {resultMsg.status === 400 ? (<p className='error text-danger errortext'>{(Object.keys(resultMsg).length > 0) ? resultMsg.message : ''}</p>) : (<p className='text-success mt-2'>{(Object.keys(resultMsg).length > 0) ? resultMsg.message : ''}</p>)}
                      {/* {resultMsg && <p className ='error text-danger errortext'>{(Object.keys(resultMsg).length > 0) ? resultMsg.message : ''}</p>} */}
                      <p className="notified mt-2">
                        Note : Currently we are serving limited <br />
                        locations
                      </p>
                    </div>
                    <div className="skip-btn" onClick={handleClose}>
                      <Link to="" closeButton>
                        Skip
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
          </div>
        </Col>
      </Row>
    </div>
  </Modal>
  );
}

export default YourLocation;